import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation } from 'react-i18next';

import Layout, { HeroSectionColors, LayoutOptions } from '../../components/Layout/Layout';

import { IconTypes } from '../../components/Icon/Icon';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import { ButtonLinkSize } from '../../components/ButtonLink/ButtonLink';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';

import './style.scss';

type Props = PageProps & WithTranslation;

const Social: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: undefined,
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-social.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const infoBlocks = [
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.IMPROVEMENTS),
      key: 'homeImprovements',
      icon: IconTypes.SETTINGS,
    },
    {
      to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM),
      key: 'meetTheTeam',
      icon: IconTypes.MEET_THE_TEAM,
    },
    {
      to: getPathFromRouteKey(RouteKeys.LOYALTY),
      key: 'eliteLoyalty',
      icon: IconTypes.ELITE_LOYALTY,
    },
    {
      to: getPathFromRouteKey(RouteKeys.PROMOTIONS),
      key: 'brokerPartnerPromotions',
      icon: IconTypes.BROKER_PROMOTIONS,
    },
    {
      to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.BROKER_PORTAL),
      key: 'brokerPortal',
      icon: IconTypes.BROKER_PORTAL,
    },
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_LITERACY),
      key: 'financialLiteracy',
      icon: IconTypes.FINANCIAL_LITERACY,
    },
    {
      to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.VIDEO_LIBRARY),
      key: 'videoLibrary',
      icon: IconTypes.VIDEO_LIBRARY,
    },
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE),
      key: 'clientResources',
      icon: IconTypes.CLIENT_RESOURCES,
    },
    {
      to: getPathFromRouteKey(RouteKeys.DEPOSITS),
      key: 'deposits',
      icon: IconTypes.DOLLAR,
    },
  ];

  return (
    <Layout options={layoutOptions} className="Social">
      <div className="grid-container">
        <div className="row row-wrap">
          <div className="column column-100 Social__generic-gap">
            <p className="h5">{t('body')}</p>
          </div>
          {infoBlocks.map((infoBlock) => (
            <div className="column column-30 InfoBlockWrapper" key={infoBlock.key}>
              <InfoBlock
                icon={infoBlock.icon}
                buttonOptions={{
                  to: infoBlock.to,
                  label: t('buttonLabel'),
                  iconOptions: {
                    icon: IconTypes.ARROW_NEXT,
                  },
                  styleOptions: {
                    isInline: true,
                    size: ButtonLinkSize.LARGE,
                  },
                  ariaLabel: t(`${infoBlock.key}.ariaButtonLabel`),
                }}
                heading={<h2 className="h3">{t(`${infoBlock.key}.heading`)}</h2>}
              >
                <p>{t(`${infoBlock.key}.body`)}</p>
              </InfoBlock>
            </div>
          ))}
          <div className="column column-30 InfoBlockWrapper" key="dummy-col" />
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.SOCIAL)(Social);
